.input-slider-line-height {
  height: 2px;
}

.input-slider-line-background {
  border-radius: 1px;
  background-color: #e5e5e5;
}

.slider-thumb {
  -webkit-appearance: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.slider-thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  opacity: 0;
  cursor: pointer;
}

.slider-thumb::-moz-range-thumb {
  position: relative;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  opacity: 0;
  cursor: pointer;
} 


.severity-range-thumb-size {
  width: 16px;
  height: 16px;
}


.position-absolute-top-for-input-range-thumb {
  top: 9px;
}

@media screen and (min-width: 576px) {
  .input-slider-line-height {
    height: 4px;
  }
  
  .position-absolute-top-for-input-range-thumb {
    top: 5px;
  }
  .severity-range-thumb-size {
    width: 24px;
    height: 24px;
  }
}