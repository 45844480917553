.setting-menu li {
  padding: 16px;
  border-radius: 8px;
}

.height-16px {
  height: 16px;
}

.setting-menu li:last-child {
  color: #e64539;
}

.height-width-16px {
  width: 16px;
  height: 16px;
}

.account-menu-setting li {
  padding: 16px;
  border-radius: 8px;
}

.hover-effect {
  background: #00a000;
  color: #ffffff;
  border-radius: 8px;
}

.hover-effect > span {
  background: #00a000;
  color: #ffffff;
  border-radius: 8px;
}

.text-align-left {
  text-align: left;
}

.height-90-percent {
  height: 90%;
}

.height-95-percent {
  height: 95%;
}

.max-width-350px {
  max-width: 350px;
}

.max-height-120px {
  max-height: 120px;
}

.max-width-150px {
  max-width: 150px;
}

.max-height-50px {
  max-height: 50px;
}

@media screen and (max-width: 1024px) {
  .small-display {
    width: 90% !important;
    align-self: center;
  }
}
