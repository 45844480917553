.max-height-100px {
  max-height: 100px;
}
.height-70-percent {
  height: 350px;
}
.invisible-scrollbar::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

.width-148px {
  width: 148px;
}
.height-40px {
  height: 40px;
}

@media screen and (max-width: 1024px) {
  .change-width {
    width: 250px;
  }
}
