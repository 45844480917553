body {
  height: 100%;
  width: 100%;
  font-family: gilroy-regular;
  background: #f1f1f1;
}

.green-background-image {
  width: 100%;
  height: 100%;
  background-image: url(./assets/images/Clinic_green_bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.grey-background-image {
  width: 100%;
  height: 100%;
  background-image: url(./assets/images/clinic_grey_bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.card-box-border {
  border-right: 2px solid rgba(0, 0, 0, 0.15);
}

.border-radius-right {
  border-radius: 0px 8px 8px 0px;
}

.min-height-100-percent {
  min-height: 100%;
}

.min-width-100px {
  min-width: 100px;
}

.max-width-100px {
  min-width: 100px;
}

.calc-height-100-percentage-50-px {
  height: calc(100% - 50px);
}

.opacity-half {
  opacity: 0.5;
}
