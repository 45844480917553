.width-14-percent {
  width: 14%;
}
.min-width-14-percent {
  min-width: 14%;
}
.width-100-percent {
  width: 100%;
}
.width-100px {
  width: 100px;
}

.height-18px {
  height: 17px;
}

.border-radius-right-default {
  border-radius: 0 8px 8px 0;
}
.height-32px {
  height: 32px;
}
.primary-menu li,
.secondary-menu li {
  height: fit-content;
  padding-left: 12px;
}
.margin-left-24px {
  margin-left: 24px;
}
.flex-column-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.border-bottom-2px {
  border-bottom: 2px solid #f1f1f1;
}
.list-style-none {
  list-style: none;
}

.white-space-nowrap {
  white-space: nowrap;
}

@media screen and (min-width: 1025px) {
  .remove-menu {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .menu-view {
    max-width: 6%;
    font-size: 12px;
    min-width: 60px;
  }
  .menu-width {
    width: fit-content !important;
  }
  .logo-view {
    padding: 8px;
    height: 44px;
    display: flex;
    justify-content: center;
    width: 44px;
  }
  .menu-remove {
    display: none;
  }
  .menu-hide {
    opacity: 0;
    display: none;
  }
}

@media screen and (min-width: 1025px) {
  .logo-view {
    display: none;
  }
}
