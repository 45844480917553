.border-radius-capsule {
  border-top-left-radius: 500px;
  border-bottom-left-radius: 500px;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
}

.min-width-300px {
  min-width: 300px;
  max-width: 300px;
}
