.splitted-input-box-disabled-padding-left-5px.disabled {
  padding-left: 5px;
}

.padding-right-4p7-em {
  padding-right: 4.7em;
}

.padding-right-0 {
  padding-right: 0;
}

.min-width-250px {
  min-width: 250px;
}

.splitted-input-box-border-none {
  border: none;
}

.border-bottom-757575-grey {
  border-bottom: 1px solid #757575;
}

.splitted-input-box:focus {
  outline: none;
}

.splitted-input-box:required {
  box-shadow: none;
}

.splitted-input-box-label {
  font-size: 16px;
  pointer-events: none;
  top: 55%;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  text-transform: uppercase;
  transform: translateY(-50%);
  color: var(--secondaryColor);
}

.splitted-input-box-label.active {
  top: 10px;
  font-size: 10px;
  color: var(--secondaryColor);
}

.splitted-input-box-label.active.disabled {
  left: 5px;
}

.splitted-input-box-border-bottom {
  position: relative;
  display: block;
  width: 100%;
}

.splitted-input-box-border-bottom:before,
.splitted-input-box-border-bottom:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: -1px;
  position: absolute;
  transition: 0.2s ease all;
  background: transparent;
}

.splitted-input-box-border-bottom:before {
  left: 50%;
}

.splitted-input-box-border-bottom:after {
  right: 50%;
}

/* active state */
.splitted-input-box.active~.splitted-input-box-border-bottom:before,
.splitted-input-box.active~.splitted-input-box-border-bottom:after {
  width: 50%;
  background: #00a000 !important;
}

/* error state */
.splitted-input-box.error~.splitted-input-box-border-bottom:before,
.splitted-input-box.error~.splitted-input-box-border-bottom:after {
  width: 50%;
  background: #e64539 !important;
}

.transform-translateY-25-percentage {
  transform: translateY(-25%);
}

.top-50-percentage {
  top: 50%;
}

.min-width-1em {
  min-width: 1em;
}

.height-width-1em {
  height: 1em;
  width: 1em;
}

.margin-left-8px {
  margin-left: 8px;
}

.border-radius-0p7em {
  border-radius: 0.7em;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.min-width-138px {
  min-width: 138px;
}

.width-40-percentage {
  width: 40%;
}

.height-fit-content {
  height: fit-content;
}

.width-95-percentage {
  width: 95%;
}

.top-70-percentage {
  top: 70%;
}

@media screen and (min-width: 576px) {
  .splitted-input-box-label {
    font-size: 24px;
  }

  .splitted-input-box-label.active {
    font-size: 15px;
    top: 12px;
  }
}