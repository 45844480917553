.width-155px {
  width: 155px;
}

.hight-40px {
  height: 40px;
}

.width-90-percent {
  width: 90%;
}

.left-40-percentage {
  width: 40%;
}

.position-absolute-top50-left60 {
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-42%, -50%);
}

.border-radius-32px {
  border-radius: 32px;
}

.gradient-top-to-bottom {
  background-image: linear-gradient(180deg, #F5F5F5 0%, rgba(245, 245, 245, 0) 100%);
}

.carousel__dot {
  width: 20%;
  height: 6px;
  border: none;
  flex-flow: column nowrap;
  border-radius: 4px;
  margin: 0 2%;
  padding: 0;
  background-color: #FFFFFF;
}

/* This class is found in DotGroup from pure-react-carousel */
/* We need to override it to add our styles */
.carousel__dot--selected {
  width: 20%;
  height: 6px;
  border-radius: 4px;
  background-color: #00A000;
  transition: background 0.4s ease;
}