.height-180px {
  height: 186px;
}

.height-120px {
  height: 150px;
}

.height-width-48px {
  height: 48px;
  width: 48px;
}

.adaptive-padding-top {
  padding-top: 24px;
}

.rotate-180 {
  transform: rotate(180deg);
}

.border-bottom-2px {
  border-bottom: 2px solid #f1f1f1;
}

@media screen and (max-width: 576px) {
  .adaptive-flex-direction {
    flex-direction: column;
  }

  .height-120px {
    height: 112px;
  }

  .adaptive-padding-top {
    padding-top: 8px;
  }
}
