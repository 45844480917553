.max-height-900px {
  max-height: 900px;
}
.height-90-percent {
  height: 90%;
}
.width-150px {
  width: 150px;
}

.max-height-width-400px {
  max-width: 500px;
  max-height: 400px;
}

.min-height-width-400px {
  min-width: 500px;
  min-height: 400px;
}

.opacity-70-percentage {
  opacity: 70%;
}
.edit-icon-position {
  position: absolute;
  top: 50%;
  left: 50%;
}

.edit-Profile-scroll::-webkit-scrollbar {
  width: 5px;
}
.edit-Profile-scroll::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
}

@media screen and (max-height: 800px) {
  .edit-Profile-scroll {
    max-height: 460px;
    overflow-y: scroll;
  }
}
