.display-inline-block {
  display: inline-block;
}

.height-width-1p2em {
  height: 1.2em;
  width: 1.2em;
}

.list-style-type-none {
  list-style-type: none;
}

.border-color-secondary-lite {
  border-color: #e5e5e5;
}

.border-top-left-right-none {
  border-top: none;
  border-left: none;
  border-right: none;
}

.height-100-percent {
  height: 100%;
}

.frosted-glass-effect {
  backdrop-filter: blur(4px);
  /* background: rgba(0, 0, 0, 0.8); */
}

.loader {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  text-indent: -9999em;
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  background: #00a000;
  background: -moz-linear-gradient(left, #00a000 10%, rgba(0, 200, 128, 0) 42%);
  background: -webkit-linear-gradient(
    left,
    #00a000 10%,
    rgba(0, 200, 128, 0) 42%
  );
  background: -o-linear-gradient(left, #00a000 10%, rgba(0, 200, 128, 0) 42%);
  background: -ms-linear-gradient(left, #00a000 10%, rgba(0, 200, 128, 0) 42%);
  background: linear-gradient(to right, #00a000 10%, rgba(0, 200, 128, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #00a000;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.loader:after {
  background: #ffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
