.height-width-16px {
	height: 16px;
	width: 16px;
}

.margin-bottom-3px {
	margin-bottom: 3px;
}

.height-80percentage {
	height: 80%;
}
