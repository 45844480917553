.width-30percent {
  width: 30%;
}

.width-155px {
  width: 155px;
}

.max-width-300px {
  max-width: 300px;
}

.border-radius {
  border-radius: 0px 50px 0px 0px;
}

.icon-img {
  width: 370px;
  height: 400px;
  position: fixed;
  top: 30%;
  bottom: 50%;
  left: 50%;
  right: 50%;
}

.align-content-center {
  align-content: center;
}

.hidden-number-scrollbar::-webkit-outer-spin-button,
.hidden-number-scrollbar::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
