.doctor-patient li:hover {
  background: #f1f1f1;
}

.doctor-patient li {
  list-style-type: none;
}

.width-16px {
  width: 16px;
}

.height-16px {
  height: 16px;
}

.account-menu li {
  padding: 16px;
  border-radius: 8px;
}

.hover-effect {
  background: #00a000;
  color: #ffffff;
  border-radius: 8px;
}

.hover-effect > span {
  background: #00a000;
  color: #ffffff;
  border-radius: 8px;
}

.height-90-percent {
  height: 90%;
}

.height-95-percent {
  height: 95%;
}

.max-width-350px {
  max-width: 350px;
}

.max-height-120px {
  max-height: 120px;
}

.max-width-150px {
  max-width: 150px;
}

.max-height-50px {
  max-height: 50px;
}

.width-fit-content {
  width: fit-content;
}

.draggle-patient-tile:hover {
  background-color: #f1f1f1;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 5px rgba(0, 0, 0, 0.12),
    0px 2px 2px rgba(0, 0, 0, 0.15);
}

.cursor-grab {
  cursor: grab;
}
