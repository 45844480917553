.height-40px {
  height: 40px;
}

.width-50-percent {
  width: 50%;
}

.width-20-percent {
  width: 20%;
}

.min-width-650px {
  min-width: 680px;
}

.max-height-64px {
  max-height: 64px;
}

.flexBasis-14-percent {
  flex-basis: 14%;
}

.min-width-270px {
  min-width: 270px;
}

.workspace-flex-basis {
  flex-basis: 72%;
}
.height-7px {
  height: 4px;
}

.margin-top-40px {
  margin-top: 40px;
}

.width-70-percent {
  width: 70%;
}

.hover-effect-menu {
  background: #00a000;
  color: #ffffff;
}

.hover-effect-menu > span {
  background: #00a000;
  color: #ffffff;
}

.side-bar-flex-basis {
  flex-basis: 14%;
}

.width-300px {
  width: 300px;
}

.overflow-auto {
  overflow: auto;
}

.height-444px {
  height: 400px;
}

@media screen and (max-width: 1024px) {
  .side-bar-flex-basis {
    flex-basis: 14%;
    max-width: 6%;
  }

  .workspace-flex-basis {
    flex-basis: 88%;
  }
}

/* document */
.overflow-y-scroll {
  overflow-y: scroll;
  scrollbar-width: none;
}

.overflow-hidden {
  overflow: hidden;
}

.height-60-percentage {
  height: 660px;
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}
.max-height-150px {
  max-height: 150px;
}

.max-height-40px {
  max-height: 40px;
}

.max-height-850px {
  max-height: 850px;
}

.max-width-800px {
  max-width: 800px;
}

.height-94-percent {
  height: 94%;
}

.height-48px {
  height: 48px;
}
.width-48px {
  width: 48px;
}
.width-125px {
  width: 125px;
}
.width-70px {
  width: 70px;
}
.height-80-percent {
  height: 80%;
}
.max-height-92-percentage {
  max-height: 92%;
}
.overflow-auto {
  overflow: auto;
}
