.toggle-button-size {
  width: 32px;
  height: 12px;
}
.toggle-button-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5e5e5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggle-button-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 0px;
  bottom: -2px;
  background-color: #00a000;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggle:checked + .toggle-button-slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.toggle-button-slider.toggle-button-thumb {
  border-radius: 34px;
}

.toggle-button-slider.toggle-button-thumb:before {
  border-radius: 50%;
}
