.height-48px {
  min-height: 48px;
}

.flex-align-items-end {
  display: flex;
  align-items: flex-end;
}

.text-area {
  background-color: transparent;
  resize: none;
}

.text-area-padding {
  max-width: 100%;
  margin-top: 1em;
  margin-right: 2em;
  margin-left: 0px;
}

.text-area-padding.disabled {
  padding-left: 5px;
}

.padding-right-4p7-em {
  padding-right: 4.7em;
}

.min-width-250px {
  min-width: 250px;
}

.text-area-border-bottom-default {
  border-bottom: 1px solid #757575;
}

.text-area:focus {
  outline: none;
}

.text-area:required {
  box-shadow: none;
}

.text-area-label {
  font-size: 16px;
  pointer-events: none;
  top: 55%;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  text-transform: uppercase;
  transform: translateY(-50%);
  color: var(--secondaryColor);
}

.text-area-label.disabled {
  left: 5px;
}

.text-area-label.light {
  color: black;
}

.text-area-label.active {
  top: 12px;
  font-size: 10px;
  color: var(--secondaryColor);
}

.text-area-border-bottom {
  position: relative;
  display: block;
  width: 100%;
}

.text-area-border-bottom:before,
.text-area-border-bottom:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  transition: 0.2s ease all;
  background: transparent;
}

.text-area-border-bottom:before {
  left: 50%;
}

.text-area-border-bottom:after {
  right: 50%;
}

/* active state */
.text-area.active ~ .text-area-border-bottom:before,
.text-area.active ~ .text-area-border-bottom:after {
  width: 50%;
  background: #00a000;
}

/* error state */
.text-area.error ~ .text-area-border-bottom:before,
.text-area.error ~ .text-area-border-bottom:after {
  width: 50%;
  background: red;
}

.top-50-percentage {
  top: 50%;
}

.min-width-1em {
  min-width: 1em;
}

.height-width-1em {
  height: 1em;
  width: 1em;
}

.margin-left-8px {
  margin-left: 8px;
}

.suffix-button-border {
  border: 1px solid #e5e5e5;
}

.border-radius-0p7em {
  border-radius: 0.7em;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.loader {
  width: 0.9em;
  height: 0.9em;
  background-color: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 2px solid var(--primaryColor);
  border-left-color: transparent;
  animation-name: spin;
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

@media screen and (min-width: 576px) and (max-width: 1024px) {
  .adaptive-font-size {
    font-size: 24px;
  }

  .adaptive-font-size.active {
    font-size: 15px;
  }
}
