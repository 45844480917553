.audio-play-icon-size {
	height: 20px;
}

.audio-player-loading-spinner-size {
	height: 20px;
	width: 20px;
}

/*Chrome*/
.audio-duration-track {
	position: relative;
	overflow: hidden;
	width: inherit;
	height: 2px;
	-webkit-appearance: none;
	background-color: #e5e5e5;
	border-radius: 1px;
}

.audio-duration-track::-webkit-slider-runnable-track {
	border-radius: 1px;
	height: 2px;
	-webkit-appearance: none;
	color: #13bba4;
}

.audio-duration-track::-webkit-slider-thumb {
	width: 4px;
	-webkit-appearance: none;
	appearance: none;
	-moz-appearance: none;
	height: 4px;
	background: #00a000;
	box-shadow: -100vw 0 0 100vw #00a000;
}

.audio-duration-track::-moz-range-thumb {
	width: 4px;
	-webkit-appearance: none;
	appearance: none;
	-moz-appearance: none;
	height: 4px;
	background: #00a000;
	box-shadow: -100vw 0 0 100vw #00a000;
}

/** FF*/
.audio-duration-track::-moz-range-progress {
	background-color: #00a000;
}

.audio-duration-track::-moz-range-track {
	background-color: #e5e5e5;
}

/* IE*/
.audio-duration-track::-ms-fill-lower {
	background-color: #00a000;
}

.audio-duration-track::-ms-fill-upper {
	background-color: #e5e5e5;
}

.audio-duration-content-size {
	width: 40px;
}

.audio-player-loading-spinner {
	animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(270deg);
	}
}

.audio-player-loading-spinner-path {
	stroke-dasharray: 187;
	stroke-dashoffset: 0;
	transform-origin: center;
	animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@keyframes colors {
	0% {
		stroke: #00a000;
	}

	25% {
		stroke: #00a000;
	}

	50% {
		stroke: #00a000;
	}

	75% {
		stroke: #00a000;
	}

	100% {
		stroke: #00a000;
	}
}

@keyframes dash {
	0% {
		stroke-dashoffset: 187;
	}

	50% {
		stroke-dashoffset: 46.75;
		transform: rotate(135deg);
	}

	100% {
		stroke-dashoffset: 187;
		transform: rotate(450deg);
	}
}

@media screen and (min-width: 576px) {
	.audio-play-icon-size {
		height: 28px;
	}

	.audio-player-loading-spinner-size {
		height: 28px;
		width: 28px;
	}
}