.height-180px {
  height: 180px;
}
.height-60px {
  height: 60px;
}

.width-48px {
  width: 48px;
}

.border-file-upload {
  border-width: 2px;
  border-style: dashed;
  border-spacing: 3px;
  border-color: #959595;
}

.width-70px {
  width: 70px;
}
.min-height-60px {
  min-height: 60px;
}

.max-width-150px {
  max-width: 150px;
}
.height-120px {
  height: 115px;
}
.height-width-48px {
  height: 48px;
  width: 48px;
}
.border-radius-50-percent {
  border-radius: 50%;
}
