.search-patient li:hover {
  background: #f1f1f1;
}

.width-16px {
  width: 16px;
}

.height-16px {
  height: 16px;
}

.account-menu div {
  padding: 16px;
  border-radius: 8px;
}

.hover-effect {
  background: #00a000;
  color: #ffffff;
  border-radius: 8px;
}

.hover-effect > span {
  background: #00a000;
  color: #ffffff;
  border-radius: 8px;
}

.height-width-12px {
  height: 12px;
  width: 12px;
}

.border-radius-capsule {
  border-top-left-radius: 500px;
  border-bottom-left-radius: 500px;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
}

.min-width-300px {
  min-width: 300px;
  max-width: 300px;
}
