/* Create a custom checkbox */
.check-box-size {
  border: 1px solid var(--primaryColor);
  height: 16px;
  width: 17px;
}

.display-none {
  display: none;
}

/* On mouse-over, add a grey background color */
.user-select-none:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.user-select-none input:checked ~ .checkmark {
  background-color: var(--primaryColor);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  z-index: 1;
}

/* Show the checkmark when checked */
.user-select-none input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.user-select-none .checkmark:after {
  left: 4px;
  top: 0px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
