.height-80-percentage {
	height: 80%;
}

.height-15-percentage {
	height: 15%;
}

.height-5-percentage {
	height: 5%;
}

.flex-grow-1 {
	flex-grow: 1;
}
.flex-wrap-wrap {
	flex-wrap: wrap;
}
.list-style-type-none {
	list-style-type: none;
}
.gap-3-percentage {
	gap: 3%;
}
.height-width-46-percentage {
	height: 46%;
	width: 46%;
}
.height-90-percentage {
	height: 90%;
}
.object-fit-cover {
	object-fit: cover;
}
.border-solid-1px-grey {
	border: 1px solid var(--tertiaryColor);
}
.bottom-10px {
	bottom: 10px;
}
.right-10px {
	right: 10px;
}
.height-width-30px{
	height: 30px;
	width: 30px;
}

.background-color-transparent-black {
	background-color: rgba(0, 0, 0, 0.637);
}