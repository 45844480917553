.border-none {
  border: none;
}

.border-bottom-1px-solid-tertiaryColor {
  border-bottom: 1px solid var(--tertiaryColor);
}

.max-height-300px {
  max-height: 300px;
}

.min-height-300px {
  min-height: 300px;
}

.overflow-y-auto {
  overflow-y: auto;
}

.left-50-percentage {
  left: 50%;
}
.transform-translateX--50-percentage {
  transform: translateX(-50%);
}
.width-112-percentage {
  width: 112%;
}
.center-card {
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  width: 112%;
}

.display-inline-block {
  display: inline-block;
}

.margin-left-auto {
  margin-left: auto;
}

.place-holder::-webkit-input-placeholder {
  /* Edge */
  font-family: gilroy-regular;
  font-size: 16px;
  color: var(--tertiaryColor);
}

.place-holder:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: gilroy-regular;
  font-size: 16px;
  color: var(--tertiaryColor);
}

.place-holder::placeholder {
  font-family: gilroy-regular;
  font-size: 16px;
  color: var(--tertiaryColor);
}

.flex-basis-100 {
  flex-basis: 100%;
}
.flex-basis-10 {
  flex-basis: 10%;
}

.max-height-80-percentage {
  max-height: 80%;
}
.overflow-x-auto {
  overflow-x: auto;
  overflow-y: visible;
}

.height-width-16-px {
  height: 16px;
  width: 16px;
}

.background-color-white {
  background: #ffff;
}
