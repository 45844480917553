.right-0 {
  right: 0;
}

.height-86-percent {
  height: 86%;
}

.width-300px {
  width: 300px;
}

.width-56px {
  width: 56px;
}

.width-14-percent {
  width: 14%;
}

.width-85-percent {
  width: 89%;
}

.max-width-155px {
  max-width: 17%;
}

.display-flex-direction-row {
  display: flex;
  flex-direction: row;
}

.display-flex-direction-column {
  display: flex;
  flex-direction: column;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

.rectangle {
  width: 264px;
  height: 2px;
  background: #f1f1f1;
}

.margin-left-24px {
  margin-left: 24px;
}

.border-bottom-1px {
  border-bottom: 1px solid #f1f1f1;
}

.border-bottom-2px {
  border-bottom: 2px solid #f1f1f1;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.notification-image {
  height: 130px;
  width: 160px;
}

.white-space-nowrap {
  white-space: nowrap;
}

.notification-image-position {
  position: absolute;
  top: 40%;
  bottom: 50%;
  left: 52%;
  right: 50%;
}

.cursor-default {
  cursor: default;
}

.turn-left {
  transform: rotate(182deg);
}

.notification-panel-size {
  position: fixed;
  right: 0;
  z-index: 1;
  max-width: 18%;
  min-width: 60px;
}

.notification-panel-size.grow,
.notification-panel-size.shrink {
  max-width: 260px;
}

.notification-hover {
  height: 60px;
}

.notification-hover:hover {
  transition: all 0.2s;
  height: fit-content;
  max-height: auto;
}

.notification-hover:hover .onHover-remove-ellipse {
  white-space: normal;
  line-height: 20px;
}
.height-92-percent {
  height: 92%;
}

/* @media screen and (max-width: 1360px) {
  .notification-hover {
    height: 60px;
  }
  .notification-hover:hover {
    height: fit-content;
    max-height: auto;
  }
  .notification-hover:hover .onHover-remove-ellipse {
    white-space: normal;
    line-height: 20px;
  }
} */

/* @media screen and (max-width: 1200px) {
  .notification-hover {
    height: 50px;
  }

  .notification-hover:hover {
    transition: all 0.2s;
    height: fit-content;
    max-height: auto;
  }
  .notification-hover:hover .onHover-remove-ellipse {
    white-space: normal;
    line-height: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .notification-hover {
    height: 50px;
  }

  .notification-hover:hover {
    transition: all 0.2s;
    height: 250px;
    max-height: auto;
  }
  .notification-hover:hover .onHover-remove-ellipse {
    white-space: normal;
    line-height: 20px;
  }
} */

@media screen and (max-width: 1024px) {
  .notification-remove {
    display: none;
  }

  .notification-panel-size {
    max-width: 6%;
  }

  .notification-panel-size.grow {
    max-width: 260px;
    min-width: 160px;
  }

  .notification-panel-size.shrink {
    max-width: 6%;
  }
}

@media screen and (min-width: 1025px) {
  .view-profile-picture {
    display: none;
  }
}
